<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer> </v-spacer>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-linear
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-linear>
            </v-layout>
            <v-btn
              color="blue darken-1"
              @click="valider_bt"
              v-if="editedItem.statut_id == '6' && editedItem.of_id && valider"
              class="mx-2"
              small
            >
              Valider
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="valider_bt"
              v-if="
                editedItem.qte_rest > 0 && editedItem.statut_id == '7' && solder
              "
              class="mx-2"
              small
            >
              Solder
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="save(6)"
              v-if="editedItem.statut_id == '6' && editedItem.of_id"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              {{ editedItem.statut_id == "6" ? "Annuler" : "Fermer" }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="1" md="2" class="mt-4">
                Date BT : {{ datefr(editedItem.bt_date) }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.id > 0">
                  <v-container fluid>
                    <v-card>
                      <v-card-title>
                        {{ "OF N°: " + order.no_order }}
                        <v-spacer></v-spacer>
                        {{
                          order.nocmd
                            ? "Commande : " +
                              order.nocmd +
                              "-" +
                              order.cmd_label
                            : ""
                        }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{
                          order.tier_name ? "Client : " + order.tier_name : ""
                        }}
                        <v-spacer></v-spacer>
                        {{ "Produit : " + order.label }}
                      </v-card-subtitle>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.of_id"
                    :item-text="
                      (item) =>
                        (item.no_order ? item.no_order + ' - ' : '') +
                        (item.cmd_label ? item.cmd_label + ' - ' : '') +
                        (item.code ? item.code + ' - ' : '') +
                        item.label
                    "
                    :item-value="'id'"
                    :items="orders_list"
                    label="Ordre de Fabrication"
                    :rules="[(v) => !!v || 'Ordre de Fabrication obligatoire']"
                    @change="order_change"
                    v-if="editedItem.id < 0"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-select
                    ref="gammes"
                    :items="gammes"
                    v-model="editedItem.gamme_id"
                    item-text="name"
                    item-value="gamme_id"
                    label="Etape de Production"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Etape obligatoire']"
                    @change="gamme_change"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="1" md="2">
                  <v-select
                    ref="machines"
                    :items="machines"
                    v-model="editedItem.machine_id"
                    item-text="label"
                    item-value="machine_id"
                    label="Machine"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Machine obligatoire']"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="editedItem.gamme_id">
                <v-col cols="12" sm="6" md="3" class="mt-2">
                  <span v-if="editedItem.id < 0">
                    {{ lib_order_qte }}
                  </span>
                  <span v-if="editedItem.id > 0">
                    {{ lib_bt_fab }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6" md="3" class="mt-2">
                  <span v-if="editedItem.id < 0">
                    {{ lib_bts_lanc }}
                  </span>
                  <span v-if="editedItem.id > 0">
                    {{ lib_bt_rest }}
                  </span>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="mt-2">
                  <span v-if="editedItem.id < 0">
                    {{ lib_rest_lanc }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    :label="
                      'Quantité' +
                      (order.unit_name ? ' (' + order.unit + ')' : '')
                    "
                    :rules="[
                      (v) =>
                        !!v ||
                        (order.unit_name ? order.unit_name : 'Quantité') +
                          ' obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                      (v) =>
                        parseFloat(v) <= parseFloat(qte_max) ||
                        item.statut_id == 7 ||
                        'Valeur Max ' +
                          numberWithSpace(qte_max.toFixed(order.decimal)),
                    ]"
                    :hint="
                      editedItem.id < 0
                        ? 'Reste ' +
                          numberWithSpace(qte_rest_lanc.toFixed(order.decimal))
                        : ''
                    "
                    :readonly="readonly"
                    autofocus
                    @keydown.enter="save"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
const confirmdialog = () => import("./ConfirmDialog.vue");
import CREATE_ORDER_BT from "../graphql/Production/CREATE_ORDER_BT.gql";
import UPDATE_ORDER_BT from "../graphql/Production/UPDATE_ORDER_BT.gql";

export default {
  components: {
    confirmdialog,
  },
  name: "btprod",
  props: {
    item: Object,
    items: Array,
    open_orders: Array,
    showForm: Boolean,
    readonly: Boolean,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
  },
  data: () => ({
    glist: false,
    imp: false,
    title: "Liste Produit",
    listload: false,
    list: false,
    progress: false,
    isListClose: true,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      bt_date: null,
      statut_name: "Brouillon",
      statut_id: "6",
    },
    editedItem: {},

    qte: 0,
    tol: 0,
    order: {},
    frml: 600,
    kl: 900,
    qte_max: 0,
    gamme: {},
    machine: {},
    alert: false,
    type_alert: "info",
    message: "",
  }),
  watch: {},
  computed: {
    gammes() {
      let l = [];
      if (this.order) l = this.order.gammes ? this.order.gammes : [];
      return l;
    },
    machines() {
      let l = [];
      if (this.gamme) l = this.gamme.machines ? this.gamme.machines : [];
      return l;
    },

    orders_list() {
      return this.open_orders;
    },

    order_qte() {
      let f = 0;

      if (this.gamme) {
        f = this.gamme.qte
          ? parseFloat(this.gamme.qte.toFixed(this.order.decimal))
          : 0;
      }

      return f;
    },
    lib_order_qte() {
      let l =
        "Planifié: " +
        this.numberWithSpace(this.order_qte.toFixed(this.order.decimal)) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return l;
    },

    qte_bts() {
      let f = 0;
      if (this.gamme) {
        f = this.gamme.qte_bt
          ? this.gamme.qte_bt.toFixed(this.order.decimal)
          : 0;
      }

      return f;
    },

    lib_bts_lanc() {
      let f =
        "Total Lancé :" +
        this.numberWithSpace(this.qte_bts) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return f;
    },
    qte_rest_lanc() {
      return parseFloat(
        (
          this.order_qte -
          this.qte_bts -
          (this.editedItem.qte ? this.editedItem.qte : 0)
        ).toFixed(this.order.decimal)
      );
    },

    lib_rest_lanc() {
      let r =
        "Reste : " +
        this.numberWithSpace(this.qte_rest_lanc) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return r;
    },
    lib_bt_fab() {
      let f =
        "Quantité Fabriquée :" +
        this.numberWithSpace(
          this.editedItem.qte_fab ? this.editedItem.qte_fab : 0
        ) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return f;
    },
    lib_bt_rest() {
      let f =
        "Reste :" +
        this.numberWithSpace(
          this.editedItem.qte_rest ? this.editedItem.qte_rest : 0
        ) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return f;
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return !this.item.no_bt
          ? "Nouveau Bon de Travail "
          : "Bon de Travail N° ".concat(this.item.no_bt);
      else return "";
    },
  },

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.order_change();
        this.gamme_change();
      } else {
        this.defaultItem.bt_date = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
      }
    }
  },

  methods: {
    order_change() {
      if (this.editedItem.id < 0) {
        let i = this.orders_list.findIndex(
          (elm) => elm.id == this.editedItem.of_id
        );
        this.order = null;
        if (i >= 0) {
          this.order = this.orders_list[i];
        }
      } else {
        this.order = this.editedItem.order;
      }
    },
    gamme_change() {
      let i = this.gammes.findIndex(
        (elm) => elm.gamme_id == this.editedItem.gamme_id
      );
      this.gamme = {};

      if (i >= 0) {
        this.gamme = this.gammes[i];
        this.editedItem.fk_order_gamme = this.gamme.id;
        if (this.gamme.machines.length == 1)
          this.editedItem.machine_id = this.gamme.machines[0].machine_id;
        this.qte_max = parseFloat(
          (
            this.gamme.qte_rest_lanc *
            (1 + (this.gamme.tol ? this.gamme.tol : 0) / 100)
          ).toFixed(this.order.decimal)
        );
      }
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async close() {
      this.$emit("close", this.editedItem);
    },
    async valider_bt() {
      if (this.$refs.form.validate())
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir " +
              (this.editedItem.statut_id == "6" ? "valider" : "solder") +
              " ce bon de travail"
          )
        ) {
          if (this.editedItem.statut_id != "6") this.save(9);
        }
    },
    async save(statut) {
      this.alert = false;
      if (this.$refs.form.validate()) {
        let ok = true;

        if (ok) {
          this.editedItem.statut_id = statut;
          let v;
          if (this.editedItem.id > 0) {
            v = {
              orderbt: {
                id: this.editedItem.id,
                no_bt: this.editedItem.no_bt,
                qte: this.editedItem.qte,
                gamme_id: this.editedItem.gamme_id,
                machine_id: this.editedItem.machine_id,
                statut_id: this.editedItem.statut_id,
                write_uid: this.$store.state.me.id,
              },
            };

            await this.maj(UPDATE_ORDER_BT, v, true);
            this.$emit("close", this.editedItem);
          } else {
            v = {
              orderbt: {
                no_bt: this.editedItem.no_bt,
                qte: this.editedItem.qte,
                gamme_id: this.editedItem.gamme_id,
                bt_date: this.editedItem.bt_date,
                of_id: this.editedItem.of_id,
                machine_id: this.editedItem.machine_id,
                statut_id: this.editedItem.statut_id,
                fk_order_gamme: this.editedItem.fk_order_gamme,

                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            v = await this.maj(CREATE_ORDER_BT, v);
            if (v) {
              this.editedItem.id = v.createOrderBT.id;

              this.$store.dispatch("Changed", true);
              this.$emit("close", this.editedItem);
            }
          }
          this.$emit("refresh", this.editedItem);
        }
      }
    },
  },
};
</script>
